import _ from "lodash";
import numeral from "numeral";
import { toInt, toFloat } from "utils";
import { toNumber } from "utils";
import { getFinancialYearName } from "utils/dashboard";
import { normalizeCurrency } from "utils/table";
import { useCubeQuery } from '@cubejs-client/react';
import { providerNameVariations } from "components/VisualizationRenderer/components/ChartRenderer/constants";
import moment from "moment";
export const chartTypeIcons = {
    "table": { icon: "table_chart" },
    "pivot-table": { icon: "pivot_table_chart" },
    "stats": { icon: "123" },
    "bar-chart": { icon: "bar_chart", rotate: true },
    "stacked-bar-chart": { icon: "stacked_bar_chart", rotate: true },
    "column-chart": { icon: "bar_chart" },
    "stacked-column-chart": { icon: "stacked_bar_chart" },
    "pie-chart": { icon: "pie_chart" },
    "donut-chart": { icon: "donut_large" },
    "line-chart": { icon: "show_chart" },
    "sankey-chart": { icon: "ssid_chart" },
    "area-chart": { icon: "area_chart" },
    "treemap-chart": { icon: "dashboard" },
    "combination-chart": { icon: "insert_chart" },
    "text": { icon: "rtt" },
    "header": { icon: "calendar_view_day" },
};

export const formatCurrency = (value, decimalpoints = 0) => {
    const currencyFormat = decimalpoints > 0 ? `$0,0.${Array(decimalpoints).fill('0').join('')}` : '$0,0';
    return numeral(normalizeCurrency(value)).format(currencyFormat);
}

export const formatDecimal = (value, decimalpoints = 0) => {
    const decimalFormat = decimalpoints > 0 ? `0,0.${Array(decimalpoints).fill('0').join('')}` : '0,0';
    return numeral(isNaN(value) ? 0 : value).format(decimalFormat);
}

export const formatChartAxisValue = (value, dataType) => {
    if (dataType === "number" || dataType === "decimal") {
        return isNaN(value) ? '0' : String(value || '0');
    }
    else if (dataType === "string") {
        return String(value || '');
    }
    return formatAmount(value);
}

export const formatChartDataLabelValue = (value, dataType, decimalpoints) => {
    if (dataType === "number") {
        return isNaN(value) ? '0' : String(value || '0');
    }
    else if (dataType === "decimal") {
        return formatDecimal(value, decimalpoints);
    }
    else if (dataType === "string") {
        return String(value || '');
    }
    return formatAmount(value);
}

export const formatChartTooltipValue = (value, dataType, decimalpoints) => {
    if (dataType === "number") {
        return isNaN(value) ? '0' : String(value || '0');
    }
    else if (dataType === "decimal") {
        return formatDecimal(value, decimalpoints);
    }
    else if (dataType === "string") {
        return String(value || '');
    }
    return formatCurrency(value, decimalpoints);
}


const formatAmount = (value,significantDigits) => {
    var sd = significantDigits ? significantDigits : 1;
    if (value >= 1000000000)
        return '$' + (value / 1000000000).toFixed(sd) + ' B'
    if (value >= 1000000)
        return '$' + (value / 1000000).toFixed(sd) + ' M'
    if (value >= 1000)
        return '$' + (value / 1000).toFixed(sd) + ' K'
    if (value <= -1000000000)
        return '$' + (value / 1000000000).toFixed(sd) + ' B'
    if (value <= -1000000)
        return '$' + (value / 1000000).toFixed(sd) + ' M'
    if (value <= -1000)
        return '$' + (value / 1000).toFixed(sd) + ' K'
    if ((Number(value) < 1 && Number(value) > -1) && Number(value) !== 0)
        return '$' + Number(value).toFixed(2)
    if (Number(value) !== 0)
        return '$' + Number(value).toFixed(1)
    return '$' + Number(value).toFixed(0)
    }

export const formatMetricAmount = (value, showDecimalPoints) => {
    value = isNaN(value) ? 0 : Number(value);
    if (value >= 1000000000)
        return '$' + (value / 1000000000).toFixed(showDecimalPoints ? 2 : 0) + 'B'
    if (value >= 1000000)
        return '$' + (value / 1000000).toFixed(showDecimalPoints ? 2 : 0) + 'M'
    if (value >= 1000)
        return '$' + (value / 1000).toFixed(showDecimalPoints ? 2 : 0) + 'K'
    return '$' + value.toFixed(showDecimalPoints ? 2 : 0)
}

export const formatMetricDeltaAmount = (value, showDecimalPoints) => {
    return (value < 0 ? "-" : "+") + formatAmount(Math.abs(value), showDecimalPoints)
}

const getMonthFilterValue = (filters, duckCube) => {
    return filters.find(filter => filter.queryName === `Months${duckCube}.month`)?.values || [];
}

const getYearNumber = (response) => {
    if (response.query?.timeDimensions?.length > 0 && response.query?.timeDimensions[0].dateRange)
        return response.query?.timeDimensions[0].dateRange[0].substring(0, 4);
    return null;
}

export const parseVizResult = (resultSet, context, vizOptions) => {
    if (!resultSet.loadResponses)
        return null;

    const filters = context.filters || [];
    const variables = context.variables || {};

    let duckCube = ""
    if (vizOptions.duck) duckCube = "Duck"
    const queryType = vizOptions.queryType;
    const targetMeasures = resultSet.loadResponse.pivotQuery.measures;
    const targetDimensions = resultSet.loadResponse.pivotQuery.dimensions?.filter(d => d !== "compareDateRange");
    // const queryDimension = vizOptions.queryDimension || targetDimensions[0];

    if (queryType === "CompareWithPrevYear") {
        let result = {};
        const selectedMonths = getMonthFilterValue(filters, duckCube);
        ["CURR_YEAR", "PREV_YEAR"].forEach((year, i) => {
            targetMeasures.forEach(measure => {
                result[`${year}.${measure}`] = resultSet.loadResponses[i]?.data?.length > 0 ? resultSet.loadResponses[i].data.reduce((sum, row) => vizOptions.pickType === "max" ? Math.max(sum, toNumber(row[measure])) : (toNumber(row[measure]) + sum), 0) : 0;
                result[`${year}.YTD.${measure}`] =
                    resultSet.loadResponses[i]?.data?.length > 0 ?
                        resultSet.loadResponses[i].data
                            .filter(row => selectedMonths.includes(row[`Months${duckCube}.month`]))
                            .reduce((sum, row) =>  vizOptions.pickType === "max" ? Math.max(sum, toNumber(row[measure])) : (toNumber(row[measure]) + sum), 0)
                        : 0;
            });
            targetDimensions.forEach(m => {
                resultSet.loadResponses[i].data.map(item => {
                    result[m] = item[m];
                })
            });
        });
        return result;
    } else if (queryType === "CompareWithYears") {
        let result = [];
        let resultSetSubq
        if (vizOptions.subquery) {
            let creatResultSetSub = () => {
                resultSetSubq = useCubeQuery(vizOptions.subquery).resultSet
                let resultSub = resultSetSubq ? resultSetSubq.loadResponses[0]?.data?.length > 0 ? resultSetSubq.loadResponses[0].data : null : null;
                if (resultSub) {
                    let keyValue = filters.filter(elem => elem.queryName === vizOptions.subqueryfilter)
                    const index = resultSub.findIndex(obj => obj[vizOptions.subqueryfilter] === keyValue[0].values[0]);
                    const tba = {
                        "Years.year": "TBA",
                        "Years.srl": null,
                        "BudgetDetails.totalBudget": null,
                        "PREV_YEAR.BudgetDetails.totalSpend": null
                    }
                    for (let i = index; i < index + 5; i++) {
                        if(resultSub[i])
                        {
                        let a = resultSet.loadResponses[0].data.filter((elem) => elem[vizOptions.subqueryfilter] === resultSub[i][vizOptions.subqueryfilter])
                        let b;
                        if (resultSub[i - 1]) {
                            b = resultSet.loadResponses[0].data.filter((elem) => elem[vizOptions.subqueryfilter] === resultSub[i - 1][vizOptions.subqueryfilter])
                        }
                        if (a.length > 0) {
                            a[0][vizOptions.config.columns[0].trendColumn.measure2] = b ? b.length > 0 ? b[0][vizOptions.config.columns[0].value] : 0 : 0
                            result.push(a[0])
                        } else {
                            a = resultSub[i]
                            a[vizOptions.config.columns[0].value] = 0
                            a[vizOptions.config.columns[0].trendColumn.measure2] = b ? b.length > 0 ? b[0][vizOptions.config.columns[0].value] : 0 : 0
                            result.push(a)
                        }
                        if (result.length === 5)
                            break;
                    }else
                    {
                        result.push(tba) 
                    }
                    }
                }
            }
            creatResultSetSub()
        }
        return (result)
    }
    else if (queryType === "CompareWithBusinessCases") {
        let result = [];
        let resultSetSubq
        if (vizOptions.subquery) {
            let creatResultSetSub = () => {
                resultSetSubq = useCubeQuery(vizOptions?.subquery)?.resultSet
                let resultSub = resultSetSubq ? resultSetSubq?.loadResponses[0]?.data?.length > 0 ? resultSetSubq.loadResponses[0].data : null : null;
                if (resultSub) {
                    const tba = {
                        "BusinessCaseDetails.businessCaseName": "TBA",
                        "BusinessCaseDetails.totalAmount": null,
                    }
                    for (let i = 0; i <= 5; i++) {
                        if(resultSub[i])
                        {
                        let a = resultSet.loadResponses[0].data.filter((elem) => elem[vizOptions.subqueryfilter] === resultSub[i][vizOptions.subqueryfilter])
                        let b;
                        if (resultSub[i - 1]) {
                            b = resultSet.loadResponses[0].data.filter((elem) => elem[vizOptions.subqueryfilter] === resultSub[i - 1][vizOptions.subqueryfilter])
                        }
                        if (a.length > 0) {
                            a[0][vizOptions.config.columns[0].trendColumn.measure2] = b ? b.length > 0 ? b[0][vizOptions.config.columns[0].value] : 0 : 0
                            result.push(a[0])
                        } else {
                            a = resultSub[i]
                            a[vizOptions.config.columns[0].value] = 0
                            a[vizOptions.config.columns[0].trendColumn.measure2] = b ? b.length > 0 ? b[0][vizOptions.config.columns[0].value] : 0 : 0
                            result.push(a)
                        }
                        if (result.length === 5)
                            break;
                    }else
                    {
                        result.push(tba) 
                    }
                    }
                }
            }
            creatResultSetSub()
        }
        return (result)
    }
    else if (queryType === "CompareWithPrevYearTrend") {
        let result = [];
        const selectedMonths = getMonthFilterValue(filters, duckCube);
        ["CURR_YEAR", "PREV_YEAR"].forEach((year, i) => {
            let r = { type: year, data: [] };
            const yearNumber = getYearNumber(resultSet.loadResponses[i]);
            r.details = { yearNumber, yearName: getFinancialYearName(yearNumber, variables["yearNameFormat"]) }
            if (resultSet.loadResponses[i]?.data?.length > 0)
                r.data = resultSet.loadResponses[i]?.data
                    .map((data, i) => {
                        let rr = { rowId: i };
                        targetDimensions.forEach(d => rr[d] = data[d])
                        targetMeasures.forEach(m => rr[m] = data[m]);
                        targetMeasures.forEach(m => {
                            if (data[`Months${duckCube}.month`] && selectedMonths.includes(data[`Months${duckCube}.month`]))
                                rr[`YTD.${m}`] = data[m];
                        });
                        return rr;
                    });
            result.push(r);
        });
        return result;
    }
    else if (queryType === "CompareWithPrevYearTrendFiltered") {
        let result = [];
        ["CURR_YEAR", "PREV_YEAR"].forEach((year, i) => {
            let r = { type: year, data: [] };
            const yearNumber = getYearNumber(resultSet.loadResponses[i]);
            r.details = { yearNumber, yearName: getFinancialYearName(yearNumber, variables["yearNameFormat"]) }
            if (resultSet.loadResponses[i]?.data?.length > 0)
                r.data = resultSet.loadResponses[i]?.data
                    .map((data, i) => {
                        let rr = { rowId: i };
                        targetDimensions.forEach(d => rr[d] = data[d])
                        targetMeasures.forEach(m => rr[m] = data[m]);
                        return rr;
                    });
            result.push(r);
        });
        return result;
    }
    else if (queryType === "Trend") {
        return resultSet.loadResponses[0].data;
    } 
    else if (queryType === 'dateDiffrence') {
        let result = new Object()
        const response = resultSet.loadResponses[0].data
        const dimension = resultSet.loadResponse.pivotQuery.dimensions[0]
        const { diffrenceBy, diffrenceIn } = vizOptions
        diffrenceBy.forEach( (val, i) => {
            const filteredResponse = response.filter( o => {
                if (diffrenceIn[i] === "days") {
                    const dayDifference = moment(new Date(o[dimension])).diff(new Date(Date.now()), 'days', true);
                    if (i===0) {
                        if (dayDifference > 0 && dayDifference <= val) {
                            return o
                        }
                    } else {
                        if (dayDifference > diffrenceBy[i-1] && dayDifference <= val ) {
                            return o
                        }
                    }
                }
                if (diffrenceIn[i] === "months") {
                    const monthDifference = moment(new Date(o[dimension])).diff(new Date(Date.now()), 'months', true);
                    if (i===0) {
                        if (monthDifference > 0 && monthDifference <= val) {
                            return o
                        }
                    } else {
                        if (monthDifference > diffrenceBy[i-1] && monthDifference <= val ) {
                            return o
                        }
                    }
                }
                if (diffrenceIn[i] === "years") {
                    const yearDifference = moment(new Date(o[dimension])).diff(new Date(Date.now()), 'years', true);
                    if (i===0) {
                        if (yearDifference > 0 && yearDifference <= val) {
                            return o
                        }
                    } else {
                        if (yearDifference > diffrenceBy[i-1] && yearDifference <= val ) {
                            return o
                        }
                    }
                }
            })
            result = { ...result, [`value${i + 1}`]: filteredResponse.length}
            // result.push({[`value${i + 1}`]: filteredResponse.length})
        })
        
        return result;
    }
    else {
        let result = {};
        const selectedMonths = getMonthFilterValue(filters, duckCube);
        targetDimensions.forEach(dimension => {
            result[dimension] =
                resultSet.loadResponses[0]?.data?.length > 0 ? resultSet.loadResponses[0].data[0][dimension] : null;
        });
        targetMeasures.forEach(measure => {
            result[measure] =
                resultSet.loadResponses[0]?.data?.length > 0 ?
                    resultSet.loadResponses[0].data
                        .reduce((sum, row) => toNumber(row[measure]) + sum, 0)
                    : 0;
            result[`YTD.${measure}`] =
                resultSet.loadResponses[0]?.data?.length > 0 ?
                    resultSet.loadResponses[0].data
                        .filter(row => selectedMonths.includes(row[`Months${duckCube}.month`]))
                        .reduce((sum, row) => toNumber(row[measure]) + sum, 0)
                    : 0;
        });
        return result;
    }
};

export const parseTableResultset = (resultSet, context, vizOptions, mergeResultSet = false) => {

    if (!resultSet)
        return null;

    const result = parseVizResult(resultSet, context, vizOptions)

    if (!result)
        return null;

    let duckCube = ""
    if (vizOptions.duck) duckCube = "Duck"
    const targetMeasures = resultSet.loadResponse.pivotQuery.measures;
    const targetDimensions = resultSet.loadResponse.pivotQuery.dimensions?.filter(d => d !== "compareDateRange");

    let processedRows = [];
    const queryType = vizOptions.queryType;
    const granularity = vizOptions.granularity || 'month';

    const timeDimensionsToExclude = granularity === "month" ? ["Years.year"] : ["Years.year", `Months${duckCube}.month`];
    let grs = _.groupBy(result[0]?.data, gd => targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).map(d => gd[d]).join("_"))
    let grss = Object.keys(grs).map(key => {
        const data = grs[key];
        let r = {};
        if (data?.length > 0) {
            r["currentYear"] = result[0].details.yearName;
            r["previousYear"] = result[1].details.yearName;
            r["Years.year"] = result[0].details.yearName;
            r["PREV_YEAR.Years.year"] = result[0].details.yearName;
            targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => r[d] = data[0][d])
            targetMeasures.forEach(m => {
                r[m] = data.reduce((sum, row) => toNumber(row[m]) + sum, 0)
                if (queryType === "CompareWithPrevYearTrend")
                    r[`YTD.${m}`] = data.reduce((sum, row) => toNumber(row[`YTD.${m}`]) + sum, 0)
            });
        }
        return r
    })

    let grs1 = _.groupBy(result[1].data, gd => targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).map(d => gd[d]).join("_"))
    let grss1 = Object.keys(grs1).map((key, i) => {
        const data = grs1[key];
        let r = { rowId: i };
        if (data?.length > 0) {
            r["currentYear"] = result[0].details.yearName;
            r["previousYear"] = result[1].details.yearName;
            targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => r[d] = data[0][d])
            targetMeasures.forEach(m => {
                r[m] = data.reduce((sum, row) => toNumber(row[m]) + sum, 0)
                if (queryType === "CompareWithPrevYearTrend")
                    r[`YTD.${m}`] = data.reduce((sum, row) => toNumber(row[`YTD.${m}`]) + sum, 0)
            });
        }
        return r
    })

    grss?.forEach(row => {
        let filterCondition = {};
        targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => filterCondition[d] = row[d])
        const relatedRowData = _.find(grss1, filterCondition);
        // console.log(filterCondition, relatedRowData)
        if (relatedRowData) {
            processedRows.push(relatedRowData.rowId)
            targetMeasures.forEach(m => {
                row[`PREV_YEAR.${m}`] = relatedRowData[m];
                if (queryType === "CompareWithPrevYearTrend")
                    row[`PREV_YEAR.YTD.${m}`] = relatedRowData[`YTD.${m}`];
            });
        }
    })
    if (mergeResultSet)
        grss1?.filter(r => !processedRows.includes(r.rowId)).forEach(row => {
            let r = { ...row };
            targetMeasures.forEach(m => {
                r[`PREV_YEAR.${m}`] = r[m];
                r[`PREV_YEAR.YTD.${m}`] = r[`YTD.${m}`];
                r[m] = 0;
                r[`YTD.${m}`] = 0;
            });
            // grss.push(r);
            grss.splice(row.rowId, 0, r);
        })

    return grss;
}

export const parseTableResultset_CF = (resultSet, context, vizOptions, mergeResultSet = false) => {

    if (!resultSet)
        return null;

    const result = parseVizResult(resultSet, context, vizOptions)

    if (!result)
        return null;

    let duckCube = ""
    if (vizOptions.duck) duckCube = "Duck"
    const targetMeasures = resultSet.loadResponse.pivotQuery.measures;
    const targetDimensions = resultSet.loadResponse.pivotQuery.dimensions?.filter(d => d !== "compareDateRange");

    let processedRows = [];
    const queryType = vizOptions.queryType;
    const granularity = vizOptions.granularity || 'month';

    const timeDimensionsToExclude = granularity === "month" ? ["Years.year"] : ["Years.year", `Months${duckCube}.month`];
    let grs = _.groupBy(result[0].data, gd => targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).map(d => gd[d]).join("_"))
    let grss = Object.keys(grs).map(key => {
        const data = grs[key];
        let r = {};
        if (data?.length > 0) {
            r["currentYear"] = result[0].details.yearName;
            r["previousYear"] = result[1].details.yearName;
            r["Years.year"] = result[0].details.yearName;
            r["PREV_YEAR.Years.year"] = result[0].details.yearName;
            targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => r[d] = data[0][d])
            targetMeasures.forEach(m => {
                r[m] = data.reduce((sum, row) => toNumber(row[m]) + sum, 0)
                if (queryType === "CompareWithPrevYearTrend")
                    r[`YTD.${m}`] = data.reduce((sum, row) => toNumber(row[`YTD.${m}`]) + sum, 0)
            });
        }
        return r
    })

    let grs1 = _.groupBy(result[1].data, gd => targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).map(d => gd[d]).join("_"))
    let grss1 = Object.keys(grs1).map((key, i) => {
        const data = grs1[key];
        let r = { rowId: i };
        if (data?.length > 0) {
            r["currentYear"] = result[0].details.yearName;
            r["previousYear"] = result[1].details.yearName;
            targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => r[d] = data[0][d])
            targetMeasures.forEach(m => {
                r[m] = data.reduce((sum, row) => toNumber(row[m]) + sum, 0)
                if (queryType === "CompareWithPrevYearTrend")
                    r[`YTD.${m}`] = data.reduce((sum, row) => toNumber(row[`YTD.${m}`]) + sum, 0)
            });
        }
        return r
    })

    grss?.forEach(row => {
        let filterCondition = {};
        targetDimensions.filter(d => !timeDimensionsToExclude.includes(d)).forEach(d => filterCondition[d] = row[d])
        const relatedRowData = _.find(grss1, filterCondition);
        if (relatedRowData) {
            processedRows.push(relatedRowData.rowId)
            targetMeasures.forEach(m => {
                row[`PREV_YEAR.${m}`] = relatedRowData[m];
                if (queryType === "CompareWithPrevYearTrend")
                    row[`PREV_YEAR.YTD.${m}`] = relatedRowData[`YTD.${m}`];
            });
        }
    })

    if (mergeResultSet)
        grss1?.filter(r => !processedRows.includes(r.rowId)).forEach(row => {
            let r = { ...row };
            targetMeasures.forEach(m => {
                r[`PREV_YEAR.${m}`] = r[m];
                r[`PREV_YEAR.YTD.${m}`] = r[`YTD.${m}`];
                r[m] = 0;
                r[`YTD.${m}`] = 0;
            });
            // grss.push(r);
            grss.splice(row.rowId, 0, r);
        })

    return grss;
}

export const parseAndMergeTableResultset = (resultSet, context, vizOptions) => {
    return parseTableResultset(resultSet, context, vizOptions, true);
};
export const parseAndMergeTableResultset_CF = (resultSet, context, vizOptions) => {
    return parseTableResultset_CF(resultSet, context, vizOptions, true);
};


export const formatValue = (format, value) => {
    if (value === undefined || value === null)
        return { originalValue: null, value: '--' };
    if (format === "string") {
        return { originalValue: value, value: String(value) || "" };
    }
    else if (format === "percent") {
        return { originalValue: Number(value), value: numeral(Math.abs(value)).format('0%') };
    }
    else if (format === "percentage") {
        return { originalValue: Number(value), value: numeral(value).format('0.0%') };
    }
    else if (format === "integer") {
        return { originalValue: Number(value), value: numeral(value).format('0') };
    }
    else if (format === "decimal") {
        return { originalValue: Number(value), value: numeral(value).format('0.00') };
    }
    else if (format === 'date') {
        return { originalValue: Number(value), value: value };
    }
    else
        return { originalValue: Number(value), value: numeral(value).format('$0,0') };
}

export const parseColumnValue = (column, result, tableResult = false) => {
    const measure1 = tableResult ? column.measure1.replace(/\./g, "__") : column.measure1;
    const measure2 = tableResult ? column.measure2.replace(/\./g, "__") : column.measure2;

    if (!result || !column)
        return null;

    if (column.type === "calculated") {
        let value = null;
        if (column.operation === "add") {
            value = (toNumber(result[measure1]) + toNumber(result[measure2])).toFixed(4);
        }
        else if (column.operation === "substract")
            value = (toNumber(result[measure1]) - toNumber(result[measure2])).toFixed(4);
        else if (["divide", "percent"].includes(column.operation)) {
            if (toNumber(result[measure2]) > 0)
                value = (toNumber(result[measure1]) / toNumber(result[measure2])).toFixed(4);
        }
        else if ("variancePercent" === column.operation) {
            if (toNumber(result[measure2]) > 0)
                value = (toNumber(result[measure1] - toNumber(result[measure2])) / toNumber(result[measure2])).toFixed(4);
        }
        // value = normalizeCurrency(value)
        let returnVal = formatValue(["percent", "variancePercent"].includes(column.operation) ? "percent" : column.format, (value));
        if (column.trendColumn) {
            const trendColumnVal = parseColumnValue(column.trendColumn, result)
            return { ...returnVal, trendColumnVal }
        }
        return returnVal;
    } else if (column.type === "date") {
        let returnVal = formatValue(column.type, result[column.value]);
        return returnVal;
    }
    else {
        let val = normalizeCurrency(result[column.value])
        let returnVal = formatValue(column.type, val);
        if (column.trendColumn) {
            const trendColumnVal = parseColumnValue(column.trendColumn, result)
            return { ...returnVal, trendColumnVal }
        }
        return returnVal;
    }
}

export const parseTableColumnValue = (column, result) => {
    return parseColumnValue(column, result, true);
}

export const pickColorNum = (value) => {
    let sum = 0;
    let value1=  value?.toUpperCase();
    for(let i=0; i<value1?.length;i++) {
      sum += value1[i]?.charCodeAt();
    }
    let num = Math.abs((sum + value1?.length)%36);
    return num
}


export const providerColorSet = (subCategory, num, colors) => {
    let  providerColor = '#000000'
    let providerName = providerNameVariations[subCategory?.toUpperCase()] 
    
    if(providerName)
    {
        providerColor = colors[providerName][num]
    }
    else
    {
        let colorNum = pickColorNum(subCategory)
        providerColor = colorNum < 36 ? colors[colorNum][num] : providerColor
    }
    return providerColor
}
export const convertRStoGraph = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let categoriesWithId = new Set();
    let i = 0;

    resultSet.forEach((item) => {

        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                categories.add(item[vizOptions.category]);
                if(vizOptions.categoryId) categoriesWithId.add({category: item[vizOptions.category] ,id: `${item[vizOptions.category]} - [${item[vizOptions.categoryId]}]`});
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            data: [],
                        };
                        i++
                    }

                    if (col.pointPadding)
                        range[col.value].pointPadding = col.pointPadding
                    if (col.pointPlacement)
                        range[col.value].pointPlacement = col.pointPlacement
                    
                    range[col.value].data.push(
                        parseInt(item[col.value])
                    );

                })
            }
        }
    });
    if(vizOptions.categoryId) return { range, categories, categoriesWithId}
    else return { range, categories }
}

export const convertRStoGraphCloud = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let categoriesWithId = new Set();
    let i = 0;

    resultSet.forEach((item) => {

        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                categories.add(item[vizOptions.category]);
                if(vizOptions.categoryId) categoriesWithId.add({category: item[vizOptions.category] ,id: `${item[vizOptions.category]} - [${item[vizOptions.categoryId]}]`});
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            data: [],
                        };
                        i++
                    }

                    if (col.pointPadding)
                        range[col.value].pointPadding = col.pointPadding
                    if (col.pointPlacement)
                        range[col.value].pointPlacement = col.pointPlacement
                    
                    range[col.value].data.push(
                        {
                            y: parseInt(item[col.value]),
                            color: providerColorSet(item[vizOptions.category], 0, colors.providerColors) // Color for the fourth bar
                        }
                        
                    );

                })
            }
        }
    });
    if(vizOptions.categoryId) return { range, categories, categoriesWithId}
    else return { range, categories }
    
}

export const convertRStoGraph_CF = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;

    resultSet.forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                categories.add(item[vizOptions.category]);
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            data: [],
                        };
                        i++
                    }
                    
                    if (col.pointPadding)
                        range[col.value].pointPadding = col.pointPadding
                    if (col.pointPlacement)
                        range[col.value].pointPlacement = col.pointPlacement

                    range[col.value].data.push(
                        parseInt(item[col.value])
                    );

                })
            }
        }
    });

    return { range, categories }
}


export const parseTableResultset2 = (resultSet, resultSet2) => {

    if (!resultSet && !resultSet2)
        return null;
    
    const data = resultSet?.loadResponses[0].data?.map(data => {return data})
    const data2 = resultSet2?.loadResponses[0].data?.map(data => {return data})
    let row = []
    data?.map(data => {
        let r ={}
        Object.keys(data).map(key => {
            if (data2?.length > 0){
                data2?.map(data2 => {
                    if (key === "CloudSpendWithForecasts.rankByYearandMonth") {
                        if (data?.["CloudTeams.name"] === data2?.["CloudTeams.name"]) {
                            r[key] = data[key];
                            r["Prev_Month__CloudSpendWithForecasts__rankByYearandMonth"] = data2?.[key] - data?.[key];
                        }
                    }
                    else {
                        r[key] = data[key];
                        r["CloudSpendWithForecasts.rankByYearandMonth"] = data["CloudSpendWithForecasts.rankByYearandMonth"]
                        r["Prev_Month__CloudSpendWithForecasts__rankByYearandMonth"] = 0
                    }
                }) 
            }
            else {
                r[key] = data[key]
                r["Prev_Month__CloudSpendWithForecasts__rankByYearandMonth"] = data && data2?.length > 0 ? data[key] : 0 - data && data2?.length  > 0 ? data2[key] : 0
            }
        })
        row.push(r)
    })
    return row
}

export const convertRStoGraphYearlyPredicted = (resultSet, colors, vizOptions) => {
    let range = {};
    let categories = [];
    let i = 0;
    let avgVal = 0;
    let totVal = 0;
    let totLen = 0;
    let lastIndex = -1;
    let lastFillIndex = -1;

    if (resultSet?.length > 0) {
        resultSet.forEach((item) => {

            if (vizOptions && vizOptions.pivot) {
                categories.add(item[vizOptions.pivot.x]);
                if (!range[item[vizOptions.pivot.y]]) {
                    range[item[vizOptions.pivot.y]] = {
                        name: item[vizOptions.pivot.y],
                        color: colors[i],
                        type: "column",
                        yAxis: 1,
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y]].data.push(
                    toInt(item[vizOptions.pivot.measure])
                );
            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    // console.log(categories);
                    vizOptions.series.map((col) => {
                        if (!range[col.value]) {
                            range[col.value] = {
                                name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                                color: colors[i],
                                type: "column",
                                yAxis: 1,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            };
                            if(vizOptions?.units)
                            range[vizOptions?.units]= { units: item[vizOptions?.units] }
                            if(vizOptions?.url) range["resourceDetails"] = { 
                                [vizOptions?.url['assetId']] : item[vizOptions?.url['assetId']],
                                [vizOptions?.url['provider']] : item[vizOptions?.url['provider']],
                                [vizOptions?.url['providerUrl']] : item[vizOptions?.url['providerUrl']]
                            }
                            i++
                            // console.log("insideRange==>",range);
                        }
                        if (toFloat(item[col.value]) !== 0) {
                            range[col.value].data[categories.indexOf(item[vizOptions.category])] = toFloat(item[col.value])
                            if (col.value === vizOptions.predictColumn) {
                               totVal = totVal + toFloat(item[col.value]);
                                totLen = totLen + 1;
                                lastIndex = categories.indexOf(item[vizOptions.category])
                            }
                            if (col.value === vizOptions.fillValuesColumn)
                                lastFillIndex = categories.indexOf(item[vizOptions.category])
                        }

                    })
                }
            }
        });

        resultSet.forEach((item) => {
            if (vizOptions && vizOptions.pivot) {
                if (!range[item[vizOptions.pivot.y] + " YTD"]) {
                    range[item[vizOptions.pivot.y] + " YTD"] = {
                        name: item[vizOptions.pivot.y] + " YTD",
                        color: colors[i],
                        type: "spline",
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y] + " YTD"].data.push(
                    (range[item[vizOptions.pivot.y] + " YTD"].data.length === 0) ?
                    toFloat(item[vizOptions.pivot.measure])
                        :
                        toFloat(range[item[vizOptions.pivot.y] + " YTD"].data[range[item[vizOptions.pivot.y] + " YTD"].data.length - 1]) + toFloat(item[vizOptions.pivot.measure])
                );

            } else {
                if (vizOptions && vizOptions.series) {
                    if(!vizOptions.disablePrediction)
                    {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    vizOptions.series.map((col) => {
                        if (!range[col.value + " YTD"]) {
                            range[col.value + " YTD"] = {
                                name: col.useNameString ? col.name + " YTD" : item[col.name] ? item[col.name] + " YTD" : '',
                                color: colors[i],
                                type: "spline",
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                zones: [{ "dashStyle": "", "value": 0, }, { "dashStyle": '', "value": 1, }, { "dashStyle": '', "value": 2, },
                                { "dashStyle": "", "value": 3, }, { "dashStyle": '', "value": 4, }, { "dashStyle": '', "value": 5, },
                                { "dashStyle": "", "value": 6, }, { "dashStyle": '', "value": 7, }, { "dashStyle": '', "value": 8, },
                                { "dashStyle": "", "value": 9, }, { "dashStyle": '', "value": 10, }, { "dashStyle": '', "value": 11, }],
                                pointStart: 0,
                                zoneAxis: 'x',
                            };
                            i++
                        }

                        if (parseInt(item[col.value]) !== 0) {
                            (categories.indexOf(item[vizOptions.category]) === 0) ?
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = toFloat(item[col.value])
                                :
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = toFloat(range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category]) - 1]) + toFloat(item[col.value])

                        }
                     })
                    }
                }
            }
        });
        if (!vizOptions.disablePrediction) 
        {
        if (range && Object.keys(range).length)
            for (i = 0; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] = (i === 0 ? (range[vizOptions.predictColumn]?.data[i] || 0) : (range[vizOptions.predictColumn + " YTD"].data[i - 1] || 0) + (range[vizOptions.predictColumn].data[i] || 0));
                range[vizOptions.fillValuesColumn + " YTD"].data[i] = (i === 0 ? (range[vizOptions.fillValuesColumn]?.data[i] || 0) : (range[vizOptions.fillValuesColumn + " YTD"].data[i - 1] || 0) + (range[vizOptions.fillValuesColumn].data[i] || 0));
            }

        if (totLen > 0) {
            avgVal = Math.round(totVal / totLen);

            for (i = lastIndex + 1; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] =
                    ((lastIndex + 1) === 0) ?
                        avgVal
                        :
                        range[vizOptions.predictColumn + " YTD"].data[i - 1] + avgVal;

                range[vizOptions.predictColumn + " YTD"].zones[i].dashStyle = 'shortdot';
                range[vizOptions.predictColumn + " YTD"].zones[i].value = i
            }
            for (i = lastFillIndex + 1; i < 12; i++) {
                range[vizOptions.fillValuesColumn + " YTD"].data[i] =
                    ((lastFillIndex + 1) === 0) ?
                        0
                        :
                        range[vizOptions.fillValuesColumn + " YTD"].data[i - 1];
            }
         }
        }
    }

    return { range, categories }

}
export const convertYearFilterAll =(vizState, vizOptions)=>{
    let updatedQuery
    let vizStatenew ={...vizState}
    if(vizOptions.queryType && vizOptions.queryType === "AllAboveYears" && !vizOptions.disableYearUpdate)
    {
      const updatedFilters = vizStatenew.query.filters.map(filter => {
        if (filter.member === "Years.year") {
            let yeraQuery =  {"order": { "Years.srl": "asc"}, "dimensions": ["Years.year", "Years.srl"],"filters":[{
                "member": "Years.year",
                "operator": "gte",
                "values": filter.values
            }]}
              let years = useCubeQuery(yeraQuery)?.resultSet?.loadResponses[0]?.data
              if(years)
              {
                years.length > 5 ? years =  years.slice(0, 5) : years
                let yearfilterd = years.map((elem)=>{return elem["Years.year"]})
                return { ...filter, values: yearfilterd};
              }
        }
        return filter;
      });
        updatedQuery = { ...vizStatenew.query, filters: updatedFilters };
        vizStatenew.query = updatedQuery
        return(vizStatenew)
    }
}
export const multiCategoryGraphData = (vizOptions, parsedResultset, colors) => {
    if (vizOptions.queryType === "AllAboveYears") {
        let graphData = { "categories": [], "range": [] }
        let result = []
        let years = [...new Set(parsedResultset.map(item => item[vizOptions.category1]))];
        years.length > 5 ? years = years.slice(0, 5) : years
        vizOptions.categoryType && vizOptions.categoryType === "Monthly" ? parsedResultset :
            parsedResultset.sort((a, b) => b[vizOptions.value] - a[vizOptions.value]);
        let categories = [...new Set(parsedResultset.map(item => item[vizOptions.category2]))];
        if (vizOptions.limit && categories.length > vizOptions.limit) {
            categories = categories.slice(0, vizOptions.limit);
        }
        years.map((el, i) => {
            let yearfilterd = parsedResultset.filter(item => item[vizOptions.category1] === el);
            let data = []
            categories.forEach((elem) => {
                let categoryfilterd = yearfilterd.filter((item => item[vizOptions.category2] === elem))
                categoryfilterd && categoryfilterd.length > 0 ? data.push(Number(categoryfilterd[0][vizOptions.value])) : data.push(0)
            })
            let obj = { "name": el, "data": data, "color": colors.graphColorsMultiple[i] }
            result.push(obj)
        })
        graphData.categories = categories
        graphData.range = result
        return (graphData)
    }
}


export const multipleStackConvert = (vizOptions, parsedResultset, colors) => {

    let range = [];
    let categories = new Set();
    let legendColors = []

    if (vizOptions.plotType && vizOptions.plotType === 'stackedMultiple') {
        legendColors = [colors.multipleStackColors[0], colors.multipleStackColors[1], '#0000FF']
        const categoriesset = [...new Set([...parsedResultset].map(item => item[vizOptions.category] || "Unknown"))];

        categoriesset.forEach((catagory) => {
            let dataSet = catagory === "Unknown" ? parsedResultset.filter((elem) => elem[vizOptions.category] === null) : parsedResultset.filter((elem) => elem[vizOptions.category] === catagory)
            for (const item of dataSet) {
                const categoryName = item[vizOptions.category] ? item[vizOptions.category] : "Unknown"
                const index = categoriesset.indexOf(categoryName) ;
                const showInLegend = categoriesset.indexOf(categoryName) === 0 && dataSet.indexOf(item) === 0;

                let previousDataSet = {
                    name: item[vizOptions.categorySub] ? item[vizOptions.categorySub] : "Unknown",
                    data: Array(categoriesset.length).fill(0),
                    stack: item[vizOptions.banner.label2],
                    showInLegend: showInLegend

                };
                previousDataSet.data[index] = { y: parseFloat(item[vizOptions.banner.value2]), color: vizOptions.providerview ? providerColorSet(previousDataSet.name.toLowerCase(), 1, colors.providerColors) : colors.multipleStackColors[0] };
                range.push(previousDataSet)

                let currentDataSet = {
                    name: item[vizOptions.categorySub] ? item[vizOptions.categorySub] : "Unknown",
                    data: Array(categoriesset.length).fill(0),
                    stack: item[vizOptions.banner.label1],
                    showInLegend: showInLegend

                };
                currentDataSet.data[index] = { y: parseFloat(item[vizOptions.banner.value1]), color: vizOptions.providerview ? providerColorSet(currentDataSet.name.toLowerCase(), 0, colors.providerColors) : colors.multipleStackColors[1] };
                range.push(currentDataSet)

            }

        })

        categoriesset.forEach(category => {
            categories.add(category);
        });

    }
    return { range, categories, legendColors }
}

export const stackedConverter = (vizOptions, parsedResultset, colors) => {
    let range = [];
    let categories = []
    if(parsedResultset.length > 0){
        let resultObjCurr = {}
        parsedResultset.forEach((item)=> {
            if (vizOptions && vizOptions.series) {
                if (categories.length === 0)
                    categories = item[vizOptions.categoryAll].split(",");

                vizOptions.series.map((col) => {
                    let duckCube = ""
                    if (vizOptions.duck) duckCube = "Duck"
                    if (col.name === `Years${duckCube}.year`) {
                        if (!resultObjCurr[item[vizOptions.subcategory]]) {
                            resultObjCurr[item[vizOptions.subcategory]] = {
                                name:  `${item[vizOptions.subcategory]}`,
                                type: "column",
                                showInLegend: true,
                                color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackColors[1],
                                stack: item[col.name],
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            };
                        }
                        resultObjCurr[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObjCurr[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                    }
            
                })
            }
        })
        range = [...Object.values(resultObjCurr)]
    }
    return { range, categories }
}
export const multipleStackConvertYearlyLine = (resultSet, colors, vizOptions) => {
    let range = [];
    let rangeYTD = !vizOptions.noPrediction ? convertRStoGraphYearlyPredicted(resultSet, colors.graphColors, vizOptions).range : null
    let categories = [];
    let i = 0;
    if (resultSet?.length > 0) {
        let resultObjPrv = {}
        let resultObjCurr = {}
        resultSet.forEach((item) => {
            if (vizOptions && vizOptions.pivot) {
                categories.add(item[vizOptions.pivot.x]);
                if (!range[item[vizOptions.pivot.y]]) {
                    range[item[vizOptions.pivot.y]] = {
                        name: item[vizOptions.pivot.y],
                        color: colors[i],
                        type: "column",
                        yAxis: 1,
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y]].data.push(
                    toInt(item[vizOptions.pivot.measure])
                );
            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    // console.log(categories);

                    vizOptions.series.map((col) => {
                        if (col.name === "previousYear" && !vizOptions.noPrediction) {
                            if (!resultObjPrv[item[vizOptions.subcategory]]) {
                                resultObjPrv[item[vizOptions.subcategory]] = {
                                    name: `${item[vizOptions.subcategory]} - [${item[col.name]}]`,
                                    yAxis: 1,
                                    type: "column",
                                    showInLegend: resultSet.indexOf(item) === 0 ? true : false,
                                    color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 1, colors.providerColors) :colors.multipleStackColors[0],
                                    stack: item[col.name],
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                };
                            }
                            resultObjPrv[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObjPrv[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                        }
                        if (col.name === "currentYear") {
                            if (!resultObjCurr[item[vizOptions.subcategory]]) {
                                resultObjCurr[item[vizOptions.subcategory]] = {
                                    name:  `${item[vizOptions.subcategory]} - [${item[col.name]}]`,
                                    yAxis: 1,
                                    type: "column",
                                    showInLegend: resultSet.indexOf(item) === 0 ? true : false,
                                    color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackColors[1],
                                    stack: item[col.name],
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                };
                            }
                            resultObjCurr[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObjCurr[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                        }
                        if(col.name && col.stackValue === "currentYear") {
                            if (!resultObjCurr[item[vizOptions.subcategory]]) {
                                resultObjCurr[item[vizOptions.subcategory]] = {
                                    name:  `${item[vizOptions.subcategory]} `,
                                    yAxis: 1,
                                    type: "column",
                                    showInLegend: vizOptions.noPrediction ? vizOptions.providerview ? true : false : resultSet.indexOf(item) === 0 ? true : false,
                                    color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackColors[1],
                                    stack: item[col.stackValue],
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                };
                            }
                            let duckCube = ""
                            if (vizOptions.duck) duckCube = "Duck"
                            resultObjCurr[item[vizOptions.subcategory]].data[Number(item[`Months${duckCube}.srl`])-1] = resultObjCurr[item[vizOptions.subcategory]].data[Number(item[`Months${duckCube}.srl`])-1] + toFloat(item[col.value])
                        }
                        if(col.name && col.stackValue === "previousYear") {
                            if (!resultObjPrv[item[vizOptions.subcategory]]) {
                                resultObjPrv[item[vizOptions.subcategory]] = {
                                    name:  `${item[vizOptions.subcategory]}`,
                                    yAxis: 1,
                                    type: "column",
                                    showInLegend: vizOptions.noPrediction ? vizOptions.providerview ? true : false : resultSet.indexOf(item) === 0 ? true : false,
                                    color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 1, colors.providerColors) :colors.multipleStackColors[0],
                                    stack: item[col.stackValue],
                                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                                };
                            }
                            resultObjPrv[item[vizOptions.subcategory]].data[Number(item["Months.srl"])-1] = resultObjPrv[item[vizOptions.subcategory]].data[Number(item["Months.srl"])-1] + toFloat(item[col.value])
                        }
                    })
                }
            }
        });
        range = [...Object.values(resultObjPrv), ...Object.values(resultObjCurr)]
        vizOptions.series.map((col) => {
            if (col.name === "previousYear" && !vizOptions.noPrediction) {
                rangeYTD[`${col.value} YTD`].stack = rangeYTD[`${col.value} YTD`].name
                rangeYTD[`${col.value} YTD`].showInLegend = true
                range.push(rangeYTD[`${col.value} YTD`])

            }

            if (col.name === "currentYear" && !vizOptions.noPrediction) {
                rangeYTD[`${col.value} YTD`].stack = rangeYTD[`${col.value} YTD`].name
                rangeYTD[`${col.value} YTD`].showInLegend = true
                range.push(rangeYTD[`${col.value} YTD`])
            }
        })
    }
    return { range, categories }
}

export const multipleStackConvertGroupedLine =  (resultSet, colors, vizOptions) => {

    let range = [];
    let categories = [];
    let i = 0;
    if (resultSet?.length > 0) {

        let resultObj1 = {}
        let resultObj2 = {}
        let resultObj3 = {}
        resultSet.forEach((item) => {
            if (vizOptions && vizOptions.pivot) {
                categories.add(item[vizOptions.pivot.x]);
                if (!range[item[vizOptions.pivot.y]]) {
                    range[item[vizOptions.pivot.y]] = {
                        name: item[vizOptions.pivot.y],
                        color: colors[i],
                        type: "column",
                        yAxis: 1,
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y]].data.push(
                    toInt(item[vizOptions.pivot.measure])
                );
            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");

                    vizOptions.series.map((col) => {
                        if(col.name === "Billed Cost") {
                            if(!resultObj1[item[vizOptions.subcategory]]) {
                                resultObj1[item[vizOptions.subcategory]] = {
                                 name: `${item[vizOptions.subcategory]} - [${col.name}]`,
                                 yAxis:1,
                                 type: 'column',
                                 showInLegend:resultSet.indexOf(item) === 0 ? true: false,
                                 color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackGroupedColors[0],
                                 stack: col.name,
                                 data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                               }
                            }
                            resultObj1[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObj1[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                        }
                        if(col.name === "Effective Cost") {
                            if(!resultObj2[item[vizOptions.subcategory]]) {
                                resultObj2[item[vizOptions.subcategory]] = {
                                 name: `${item[vizOptions.subcategory]} - [${col.name}]`,
                                 yAxis:1,
                                 type: 'column',
                                 showInLegend:resultSet.indexOf(item) === 0 ? true: false,
                                 color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackGroupedColors[1],
                                 stack: col.name,
                                 data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                               }
                            }
                            resultObj2[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObj2[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                        }
                        if(col.name === "List Cost") {
                            if(!resultObj3[item[vizOptions.subcategory]]) {
                                resultObj3[item[vizOptions.subcategory]] = {
                                 name: `${item[vizOptions.subcategory]} - [${col.name}]`,
                                 yAxis:1,
                                 type: 'column',
                                 showInLegend:resultSet.indexOf(item) === 0 ? true: false,
                                 color: vizOptions.providerview ? providerColorSet(item[vizOptions.subcategory], 0, colors.providerColors) : colors.multipleStackGroupedColors[2],
                                 stack: col.name,
                                 data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                               }
                            }
                            resultObj3[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] = resultObj3[item[vizOptions.subcategory]].data[categories.indexOf(item[vizOptions.category])] + toFloat(item[col.value])
                        }
                       
                    })
                }
            }
        });
        range = [...Object.values(resultObj1), ...Object.values(resultObj2), ...Object.values(resultObj3)]   
    }
    return { range, categories }

}

export const  creatToggleTableCubeOptions = (cubeOptions) => {
    let { cubeId, vizData = {} } = cubeOptions;
    let tableCubeOptions = {
        cubeId,
        chartType: "table",
        chartConfig: {},
        vizData: {
            values: [...(vizData.axis || []), ...(vizData.legend || []), ...(vizData.values || [])],
            filters: vizData.filters ? [...vizData.filters] : [],
            sort: vizData.sort ? [...vizData.sort] : []
        }
    };
    return tableCubeOptions;
};
