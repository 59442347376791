// import Highcharts from 'highcharts';
import produce, { setAutoFreeze } from "immer";
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import { toNumber } from "utils";
import { toInt } from "utils";
import { formatChartTooltipValue } from "utils/charts";
import { formatChartDataLabelValue } from "utils/charts";
import { formatChartAxisValue } from "utils/charts";
import { chartwiseDefaultColorThemes, colorThemes, highChartTypeForChartType, horizRefLineLabelPostion, legendPosition, vertRefLineLabelPostion } from "./constants";
import { getMonthsQuarters } from "utils/dashboard";

const baseConfig = {
    chart: {
        backgroundColor: 'transparent',
        style: { fontFamily: 'inherit', fontSize: '14px' },
        events: {
            load: function () {
                let yAxis = this.yAxis[0],
                    plotLines = yAxis.plotLinesAndBands[0];

                if (plotLines) {
                    if (plotLines.options.value >= yAxis.max) {
                        yAxis.update({
                            max: plotLines.options.value * 1.1
                        })
                    }
                }
            }
        }
    },
    title: { text: '' },
    subtitle: { text: '' },
    exporting: { enabled: false },
    credits: { enabled: false },
    lang: { thousandsSep: ',' },
    xAxis: {
        title: {
            enabled: false,
        },
        gridLineColor: '#D0D0DA30',
        labels: {
            formatter: function () {
                return (String(this.value) || "").split("_br_").join("<br>");
            },
        }
    },
    yAxis: {
        title: {
            enabled: false,
        },
        gridLineColor: '#D0D0DA30',
        // crosshair: true
    },
    series: [],
    plotOptions: {},
    colors: colorThemes["default"],
    legend: { align: 'center' },
    tooltip: {
        followPointer: true,
        useHTML: true
    }
};

const getPlotOptions = (chartType, seriesDataType, seriesDecimalDataTypeDecimalpoints, isPercentageStacking, enableDatalabelsVal, enableDataMarkersVal, datalabelsFilterPercentVal, insideDatalabelsVal, sankeyNodeWidthVal, sankeyMinLinkWidthVal) => {
    if (["bar-chart"].includes(chartType)) {
        return {
            bar: {
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }
    if (["stacked-bar-chart"].includes(chartType)) {
        return {
            bar: {
                stacking: isPercentageStacking ? 'percent' : 'normal',
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }
    else if (["column-chart"].includes(chartType)) {
        return {
            column: {
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }
    else if (["stacked-column-chart"].includes(chartType)) {
        return {
            column: {
                stacking: isPercentageStacking ? 'percent' : 'normal',
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }
    else if (["pie-chart", "donut-chart"].includes(chartType)) {
        return {
            pie: {
                // allowPointSelect: true,
                // cursor: 'pointer',
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                    distance: insideDatalabelsVal ? -50 : 30,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        value: datalabelsFilterPercentVal
                    }
                },
                showInLegend: true
            }
        }
    }
    else if (["sankey-chart"].includes(chartType)) {
        return {
            sankey: {
                nodeWidth: sankeyNodeWidthVal || 20,
                nodePadding: 10,//sankeyNodePaddingVal || 10,
                minLinkWidth: sankeyMinLinkWidthVal || 10,
            }
        }
    }
    else if (["line-chart"].includes(chartType)) {
        return {
            spline: {
                lineWidth: 3,
                states: {
                    hover: {
                        lineWidth: 4
                    }
                },
                marker: {
                    enabled: enableDataMarkersVal
                },
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }
    else if (["area-chart"].includes(chartType)) {
        return {
            areaspline: {
                pointPlacement: 'on',
                stacking: isPercentageStacking ? 'percent' : 'normal',
                marker: {
                    enabled: false
                },
                dataLabels: {
                    enabled: enableDatalabelsVal,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.y), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            }
        }
    }

    return null;
}

const handleTimeFormat = (a, value) => {
    if (a.dataType && a.dataType === "time" && value) {
        value = moment(value).format(a.dateFormat || "DD/MM/YYYY");
    }
    return value;
}

const MonthField = "Months.month";
const QuarterField = "Months.qtr";

function getMonthsSortingOrder(data, monthsArray) {
    const monthsInData = _.uniqBy(data, v => v[MonthField] || "").map(v => v[MonthField]);
    if (monthsInData?.length >= 2) {
        if (monthsArray.indexOf(monthsInData[0]) < monthsArray.indexOf(monthsInData[1])) {
            return "asc";
        }
        else
            return "desc";
    }

    return "asc";
}

function getCategories(axis, standardVariables, data) {
    let categories = [];

    // axis has Month
    const monthAxis = axis?.find(a => a.name === MonthField);
    const showAllMonths = monthAxis && monthAxis.showAllMonths === true;
    const hasQuarterAxis = axis?.find(a => a.name === QuarterField);
    const hasAdditionalAxes = axis?.length > 1;

    if (showAllMonths) {
        const monthsQuarters = getMonthsQuarters(standardVariables.firstMonth);
        let monthsArray = Object.keys(monthsQuarters);
        const sortOrder = getMonthsSortingOrder(data, monthsArray);
        if (sortOrder === "desc")
            monthsArray = monthsArray.reverse();

        if (hasAdditionalAxes) {
            const otherCategories = [];
            _.uniqBy(
                data,
                v => axis
                    .filter(a => ![MonthField, QuarterField].includes(a.name))
                    .map(
                        a => {
                            let value = v[a.name] || "";
                            return handleTimeFormat(a, value);
                        })
                    .join()
            )
                .forEach(
                    c => {
                        let catObj = axis
                            .filter(a => ![MonthField, QuarterField].includes(a.name))
                            .reduce((acc, a) => {
                                acc[a.name] = handleTimeFormat(a, c[a.name] || "");
                                return acc;
                            }, {});

                        // add all months
                        monthsArray.forEach(month => {
                            otherCategories.push({
                                ...catObj,
                                [MonthField]: month,
                                ...(hasQuarterAxis && { [QuarterField]: monthsQuarters[month] })
                            });
                        });
                    }
                );

            categories = otherCategories?.map(
                i => axis.map(a => {
                    let value = i[a.name] || "";
                    return handleTimeFormat(a, value);
                })
                    .join("_br_") || "(empty)"
            )
                || [];
        }

        else
            categories = monthsArray;
    }
    else {
        categories = axis?.length > 0
            ? _.uniqBy(
                data,
                v => axis.map(
                    a => {
                        let value = v[a.name] || "";
                        return handleTimeFormat(a, value);
                    })
                    .join()
            )
                .map(
                    i => axis.map(a => {
                        let value = i[a.name] || "";
                        return handleTimeFormat(a, value);
                    })
                        .join("_br_") || "(empty)"
                )
            : [];
    }
    return categories;
}

const getChartData = (chartType, vizOptions, data, standardVariables, donutInnerRadius, sankeySeriesName) => {
    let chartData = {};

    switch (chartType) {
        case "line-chart":
        case "area-chart":
        case "bar-chart":
        case "stacked-bar-chart":
        case "column-chart":
        case "stacked-column-chart": {
            const { axis, values, legend } = vizOptions;
            let categories = getCategories(axis, standardVariables, data);
            let range = {};

            data?.forEach((item) => {
                if (legend?.length > 0 && axis?.length > 0) {
                    const groupedData = _.groupBy(data, (item) => {
                        return [legend.map(l => item[l.name] || "")?.join(" ") || "(empty)"]
                    });
                    Object.keys(groupedData)?.map((k) => {
                        const val1 = values[0];
                        if (!range[k]) {
                            range[k] = {
                                name: k || "(empty)",
                                dataType: val1.dataType,
                                decimalPoints: val1.decimalPoints,
                                // borderRadius: 4,
                                data: [],
                            };
                        }

                        let valsTempl = {};
                        categories?.forEach(c => {
                            valsTempl[c] = null;
                        });

                        groupedData[k]?.forEach(item =>
                            valsTempl[axis.map(a => item[a.name] || "")?.join("_br_") || "(empty)"] = toNumber(item[val1.name] || 0)
                        )
                        range[k].data = Object.values(valsTempl)
                    });
                }
                else {
                    values?.map((val) => {
                        const rangeName = val.titleAlias || val.title;
                        if (!range[rangeName]) {
                            range[rangeName] = {
                                name: rangeName,
                                dataType: val.dataType,
                                decimalPoints: val.decimalPoints,
                                borderRadius: 4,
                                data: Array(categories.length).fill(0),
                            };
                        }
                        if (axis?.length > 0) {
                            const key = axis.map(a => item[a.name] || "")?.join("_br_") || "(empty)"
                            range[rangeName].data[categories.indexOf(key)] = toNumber(item[val.name] || 0)
                        }
                        else
                            range[rangeName].data.push(
                                toNumber(item[val.name] || 0)
                            );
                    });
                }
            });

            chartData = {
                categories,
                series: Object.values(range)
            }
            break;
        }
        case "combination-chart": {
            const { axis, values, values1, legend } = vizOptions;
            let categories = getCategories(axis, standardVariables, data);
            let range = {};

            data?.forEach((item) => {
                if (legend?.length > 0 && axis?.length > 0) {
                    const groupedData = _.groupBy(data, (item) => {
                        return [legend.map(l => item[l.name] || "")?.join(" ") || "(empty)"]
                    });
                    Object.keys(groupedData)?.map((k) => {
                        const val1 = values[0];
                        if (!range[k]) {
                            range[k] = {
                                name: k || "(empty)",
                                dataType: val1.dataType,
                                decimalPoints: val1.decimalPoints,
                                type: 'column',
                                borderRadius: 4,
                                yAxis: 1,
                                data: [],
                            };
                        }

                        let valsTempl = {};
                        categories?.forEach(c => {
                            valsTempl[c] = null;
                        });

                        groupedData[k]?.forEach(item =>
                            valsTempl[axis.map(a => item[a.name] || "")?.join("_br_") || "(empty)"] = toNumber(item[val1.name] || 0)
                        )
                        range[k].data = Object.values(valsTempl)
                    });
                }
                else {
                    values?.map((val) => {
                        const rangeName = val.titleAlias || val.title;
                        if (!range[rangeName]) {
                            range[rangeName] = {
                                name: rangeName || "(empty)",
                                dataType: val.dataType,
                                decimalPoints: val.decimalPoints,
                                type: 'column',
                                borderRadius: 4,
                                data: Array(categories.length).fill(0),
                            };
                        }

                        if (axis?.length > 0) {
                            const key = axis.map(a => item[a.name] || "")?.join("_br_") || "(empty)"
                            range[rangeName].data[categories.indexOf(key)] = toNumber(item[val.name] || 0)
                        }
                        else
                            range[rangeName].data.push(
                                toNumber(item[val.name] || 0)
                            );
                    });
                    values1?.map((val) => {
                        const rangeName = val.titleAlias || val.title;
                        if (!range[rangeName]) {
                            range[rangeName] = {
                                name: rangeName || "(empty)",
                                dataType: val.dataType,
                                decimalPoints: val.decimalPoints,
                                type: 'spline',
                                yAxis: 1,
                                data: Array(categories.length).fill(0),
                            };
                        }

                        if (axis?.length > 0) {
                            const key = axis.map(a => item[a.name] || "")?.join("_br_") || "(empty)"
                            range[rangeName].data[categories.indexOf(key)] = toNumber(item[val.name] || 0)
                        }
                        else
                            range[rangeName].data.push(
                                toNumber(item[val.name] || 0)
                            );
                    });
                }
            });

            chartData = {
                categories,
                series: Object.values(range)
            }
            break;
        }
        case "pie-chart": {
            const { axis, values } = vizOptions;
            let range = {};
            data?.forEach((item) => {
                values?.map((val) => {
                    if (!range[val.titleAlias || val.title]) {
                        range[val.titleAlias || val.title] = {
                            name: val.titleAlias || val.title || "(empty)",
                            dataType: val.dataType,
                            decimalPoints: val.decimalPoints,
                            colorByPoint: true,
                            data: [],
                        };
                    }
                    const dataPointName = axis?.length > 0
                        ? axis.map(a => {
                            let value = item[a.name] || "";
                            return handleTimeFormat(a, value)
                        }).join(" ")
                        : "(empty)";
                    range[val.titleAlias || val.title].data.push({
                        name: dataPointName,
                        y: toNumber(item[val.name] || 0)
                    });
                });
            });

            chartData = {
                series: Object.values(range)
            }
            break;
        }
        case "donut-chart": {
            const { axis, values } = vizOptions;
            let range = {};
            data?.forEach((item) => {
                values?.map((val) => {
                    if (!range[val.titleAlias || val.title]) {
                        range[val.titleAlias || val.title] = {
                            name: val.titleAlias || val.title || "(empty)",
                            dataType: val.dataType,
                            decimalPoints: val.decimalPoints,
                            colorByPoint: true,
                            innerSize: donutInnerRadius ? donutInnerRadius : "50%",
                            data: [],
                        };
                    }
                    const dataPointName = axis?.length > 0
                        ? axis.map(a => {
                            let value = item[a.name] || "";
                            return handleTimeFormat(a, value)
                        }).join(" ")
                        : "(empty)";
                    range[val.titleAlias || val.title].data.push({
                        name: dataPointName,
                        y: toNumber(item[val.name] || 0)
                    });
                });
            });

            chartData = {
                series: Object.values(range)
            }
            break;
        }
        case "treemap-chart": {
            const { axis, values } = vizOptions;
            let range = {};
            data?.forEach((item) => {
                values?.map((val) => {
                    if (!range[val.titleAlias || val.title]) {
                        range[val.titleAlias || val.title] = {
                            name: val.titleAlias || val.title || "(empty)",
                            dataType: val.dataType,
                            decimalPoints: val.decimalPoints,
                            type: 'treemap',
                            layoutAlgorithm: 'squarified',
                            colorByPoint: true,
                            data: [],
                        };
                    }
                    const dataPointName = axis?.length > 0
                        ? axis.map(a => {
                            let value = item[a.name] || "";
                            return handleTimeFormat(a, value)
                        }).join(" ")
                        : "(empty)";
                    range[val.titleAlias || val.title].data.push({
                        name: dataPointName,
                        value: toNumber(item[val.name] || 0),
                    });
                });
            });

            chartData = {
                series: Object.values(range)
            }
            break;
        }
        case "sankey-chart": {
            const { axis, values } = vizOptions;
            let dataArr = [];
            let nodesObj = {};
            let labels = [];
            let seriesName = "";

            if (values.length > 0 && axis?.length > 0 && values[0].title) {

                seriesName = sankeySeriesName || values[0].titleAlias || values[0].title;

                nodesObj[`${values[0].name}__${values[0].titleAlias || values[0].title}`] = {
                    id: `${values[0].name}__${values[0].titleAlias || values[0].title}`,
                    name: values[0].titleAlias || values[0].title
                };
                labels.push(values[0].titleAlias || values[0].title);

                const firstCategory = _.uniqBy(data, v => {
                    let value = v[axis[0].name] || "";
                    return handleTimeFormat(axis[0], value)
                }).map(i => {
                    let axisValue = i[axis[0].name] || "";
                    axisValue = handleTimeFormat(axis[0], axisValue)
                    if (!nodesObj[`${axis[0].name}__${axisValue}`] && axisValue)
                        nodesObj[`${axis[0].name}__${axisValue}`] = {
                            id: `${axis[0].name}__${axisValue}`,
                            name: axisValue
                        };

                    const fRows = _.filter(data, [axis[0].name, i[axis[0].name]]) || [];

                    return ({
                        from: `${values[0].name}__${values[0].titleAlias || values[0].title}`,
                        to: axisValue ? `${axis[0].name}__${axisValue}` : undefined,
                        weight: fRows.reduce((sum, row) => Number(row[values[0].name]) + sum, 0)
                    });
                });

                if (firstCategory?.length > 0)
                    dataArr.push(...firstCategory)

                axis?.forEach((c, i) => {
                    labels.push(c.titleAlias || c.title);
                    if (i > 0) {
                        const prevCategoryKey = axis[i - 1].name;
                        const grouped = _.groupBy(data, v => {
                            let prevValue = v[prevCategoryKey] || "";
                            let currentValue = v[axis[i].name] || "";
                            prevValue = handleTimeFormat(axis[i - 1], prevValue)
                            currentValue = handleTimeFormat(c, currentValue)
                            return `${prevCategoryKey}__${prevValue}_|_${axis[i].name}__${currentValue}`;
                        });
                        const cats = [];
                        Object.keys(grouped).forEach(k => {
                            const gRows = grouped[k];
                            const kArr = k.split("_|_");

                            if (!nodesObj[kArr[1]] && kArr[1].split("__")[1] && kArr[1].split("__")[1])
                                nodesObj[kArr[1]] = {
                                    id: kArr[1],
                                    name: kArr[1].split("__")[1]
                                };

                            if (kArr[0].split("__")[1] !== "null")
                                cats.push({
                                    from: kArr[0],
                                    to: kArr[1].split("__")[1] !== "null" ? kArr[1] : undefined,
                                    weight: gRows.reduce((sum, row) => Number(row[values[0].name]) + sum, 0)
                                });
                        });

                        dataArr.push(...cats);
                    }
                });
            }

            const series = [{
                keys: ['from', 'to', 'weight'],
                data: dataArr,
                nodes: Object.values(nodesObj),
                type: 'sankey',
                name: seriesName,
                dataType: values[0].dataType,
                decimalPoints: values[0].decimalPoints,
            }];

            chartData = {
                series,
                labels
            }
            break;
        }
        default:
            break;
    }
    return chartData;
};

const getSeriesTypeByName = (name, series) => {
    return series?.find(s => s.name === name)?.dataType || "currency";
}

const getSeriesDecimalTypeDecimalpointsByName = (name, series) => {
    return series?.find(s => s.name === name)?.decimalPoints || 0;
}

const getPlotLines = (enableRefLine, refLinesConfig, refLineValue, refLineStyle, refLineColor, refLineLabel, refLineLabelHorizPosition, refLineLabelVertPosition, refLineXValue, refLineYValue, refLineLabelColor, refLineZPosition) => {
    if (!enableRefLine)
        return [];
    if (refLinesConfig && Array.isArray(refLinesConfig)) {
        return refLinesConfig.map(c => {
            const xPosition = horizRefLineLabelPostion[c.refLineLabelHorizPosition || 'right'];
            const yPosition = vertRefLineLabelPostion[c.refLineLabelVertPosition || 'bottom'];
            const xLabelPosition = c.refLineXValue ? toInt(c.refLineXValue) : xPosition;
            const yLabelPosition = c.refLineYValue ? toInt(c.refLineYValue) : yPosition;
            return ({
                value: Number(c.refLineValue),
                dashStyle: c.refLineStyle || 'dash',
                ...((c.refLineZPosition || "front") === "front" && { zIndex: 100 }),
                width: 2,
                color: c.refLineColor,
                label: {
                    text: c.refLineLabel || '',
                    align: c.refLineLabelHorizPosition || 'right',
                    verticalAlign: c.refLineLabelVertPosition || 'bottom',
                    rotation: 0,
                    x: xLabelPosition,
                    y: yLabelPosition,
                    style: {
                        color: c.refLineLabelColor || refLineLabelColor,
                        fontSize: "12px",
                        fontWeight: "bold",
                    }
                }
            })
        });
    }

    if (!isNaN(refLineValue)) {
        const xPosition = horizRefLineLabelPostion[refLineLabelHorizPosition];
        const yPosition = vertRefLineLabelPostion[refLineLabelVertPosition];
        const xLabelPosition = refLineXValue ? toInt(refLineXValue) : xPosition;
        const yLabelPosition = refLineYValue ? toInt(refLineYValue) : yPosition;
        return [{
            value: Number(refLineValue),
            dashStyle: refLineStyle || 'dash',
            ...((refLineZPosition || "front") && { zIndex: 100 }),
            width: 2,
            color: refLineColor,
            label: {
                text: refLineLabel || '',
                align: refLineLabelHorizPosition,
                verticalAlign: refLineLabelVertPosition,
                rotation: 0,
                x: xLabelPosition,
                y: yLabelPosition,
                style: {
                    color: refLineLabelColor,
                    fontSize: "12px",
                    fontWeight: "bold",
                }
            }
        }];
    }

    return [];
}

const getChartOptions = (chartType, vizOptions, data, standardVariables) => {
    const config = vizOptions.config;
    const textColorVal = config["text_color"] || "#666666";
    const themeColorsVal = config["theme_colors"] || "default";
    const reverseThemeColorsVal = Boolean(config["theme_colors_reverse"]);
    const disableTooltipSharedVal = Boolean(config["tooltip_shared_disable"]);
    const disableLegendVal = Boolean(config["legend_disable"]);
    const floatingLegendVal = Boolean(config["legend_floating"]);
    const legendSymbolTypeConfigVal = config["legend_symbol"] || "circle";
    const legendPositionConfigVal = config["legend_position"] || "bottom";
    const legendPositionVal = legendPosition[legendPositionConfigVal];
    const layoutVal = ["right", "rightTop", "rightBottom", "left", "leftTop", "leftBottom"].includes(legendPositionConfigVal) ? "vertical" : "horizontal";
    const disableXaxisVal = Boolean(config["xAxis_disable"]);
    const reverseXaxisVal = Boolean(config["xAxis_reverse"]);
    const reverseYaxisVal = Boolean(config["yAxis_reverse"]);
    const secondaryXaxisVal = Boolean(config["xAxis_secondary"]);
    const secondaryYaxisVal = Boolean(config["yAxis_secondary"]);
    const hideXaxisLineVal = Boolean(config["xAxisLine_hide"]);
    const disableYaxisVal = Boolean(config["yAxis_disable"]);
    const hideYaxisLineVal = Boolean(config["yAxisLine_hide"]);
    const yAxis1TitleVal = config["yAxis1_title"];
    const yAxis2TitleVal = config["yAxis2_title"];
    const enableDatalabelsVal = Boolean(config["datalabels_enable"]);
    const enableDataMarkersVal = Boolean(config["datamarkers_enable"]);
    const enableStackDatalabelsVal = Boolean(config["stackdatalabels_enable"]);
    const insideDatalabelsVal = Boolean(config["datalabels_inside"]);
    const datalabelsFilterPercentVal = config["datalabels_filter_percent"] ? config["datalabels_filter_percent"] : 1;
    const isPercentageStacking = Boolean(config["percentage_stacking"]);
    const hideLabels = Boolean(config["labels_hide"]);
    const labelsPosition = config["labels_position"];
    const donutInnerRadius = config["donut_innerRadius"];
    const sankeySeriesName = config["sankeySeriesName"];
    const sankeyNodeWidthVal = config["sankeyNodeWidth"];
    // const sankeyNodePaddingVal = config["sankeyNodePadding"];
    const sankeyMinLinkWidthVal = config["sankeyMinLinkWidth"];
    const enableRefLineVal = Boolean(config["refLine_enable"]);
    const refLineValueVal = config["refLine_value"];
    const refLineStyleVal = config["refLine_style"];
    const refLineLabelVal = config["refLine_label"];
    const refLineLabelHorizPositionVal = config["refLine_label_horiz_position"] || 'right';
    const refLineLabelVertPositionVal = config["refLine_label_vert_position"] || 'bottom';
    const refLineColorVal = config["refLine_color"] || "#666666";
    const refLineXValueVal = config["refLine_x_value"];
    const refLineYValueVal = config["refLine_y_value"];
    const refLineZPositionVal = config["refLine_z_position"] || "front";
    const refLinesConfig = config["refLinesConfig"];
    const tickIntervalVal = config["tick_interval"] || 1;

    const customColors = themeColorsVal === "custom";
    const customColorSwatch = config["theme_colors_custom"] || [];
    let colorSwatch = [];

    if (customColors)
        colorSwatch = [...customColorSwatch];
    else {
        // use chart specific default theme first
        if (themeColorsVal === "default")
            colorSwatch = chartwiseDefaultColorThemes[chartType] || colorThemes["default"] || [];
        else
            colorSwatch = colorThemes[themeColorsVal] || colorThemes["default"] || [];
    }

    if (reverseThemeColorsVal)
        colorSwatch = [].concat(colorSwatch)?.reverse();

    // add default colors as extra colors
    colorSwatch = [...colorSwatch].concat(colorThemes["default"]);

    const chartData = getChartData(chartType, vizOptions, data, standardVariables, donutInnerRadius, sankeySeriesName);

    const seriesDataType = chartData.series?.length > 0 ? chartData.series[0].dataType || "currency" : "currency";

    const seriesDecimalDataTypeDecimalpoints = chartData.series?.length > 0 ? chartData.series[0].decimalPoints || 0 : 0;

    const plotOptions = getPlotOptions(chartType, seriesDataType, seriesDecimalDataTypeDecimalpoints, isPercentageStacking, enableDatalabelsVal, enableDataMarkersVal, datalabelsFilterPercentVal, insideDatalabelsVal, sankeyNodeWidthVal, sankeyMinLinkWidthVal);

    let chartOptions = {
        chartType: highChartTypeForChartType[chartType],
        colors: colorSwatch,
        legend: {
            enabled: !disableLegendVal,
            floating: floatingLegendVal,
            layout: layoutVal,
            align: legendPositionVal.align,
            verticalAlign: legendPositionVal.verticalAlign,
            symbolRadius: legendSymbolTypeConfigVal === "square" ? 0 : undefined,
            itemStyle: {
                color: textColorVal
            },
            navigation: {
                activeColor: '#3E576F',
                animation: true,
                arrowSize: 12,
                inactiveColor: '#CCC',
                style: {
                    fontWeight: 'normal',
                    color: '#333',
                    fontSize: '12px'
                }
            }
        },
        plotOptions
    };

    chartOptions.tooltip = {
        formatter: function () {
            let tooltipHtml = `<table tablespacing="0" tablepadding="0"><tr>
                    <th colspan="3" style="padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd">
                        ${(this.x || this.key || "").replace(/_br_/g, " ")}
                    </th>
                </tr>`;
            if (this.points)
                this.points?.forEach(p => {
                    const sDataType = getSeriesTypeByName(p.series.name, chartData.series);
                    const sDataTypeDEcimalpoints = getSeriesDecimalTypeDecimalpointsByName(p.series.name, chartData.series);
                    tooltipHtml += `<tr>
                    <td>
                        <div style="height: 10px; width: 10px; background-color: ${p.color}; margin-right: 3px"></div></td>
                    <td>${p.series.name}</td>
                    <td style="text-align: right; padding-left: 3px">
                        <b>${formatChartTooltipValue(p.y, sDataType, sDataTypeDEcimalpoints)}</b>
                    </td>
                </tr>
            `});
            else {
                const sDataType = getSeriesTypeByName(this.series.name, chartData.series);
                const sDataTypeDEcimalpoints = getSeriesDecimalTypeDecimalpointsByName(this.series.name, chartData.series);
                tooltipHtml += ` <tr>
                    <td>
                        <div style="height: 10px; width: 10px; background-color: ${this.color}; margin-right: 3px"></div>
                        </td>
                    <td>${this.series?.name}</td>
                    <td style="text-align: right; padding-left: 3px">
                        <b>${formatChartTooltipValue(this.y, sDataType, sDataTypeDEcimalpoints)}</b>
                    </td>
                </tr>
            `;
            }
            tooltipHtml += '</table>';
            return tooltipHtml;
        },
    };

    switch (chartType) {
        case "bar-chart":
        case "stacked-bar-chart":
            chartOptions.tooltip.shared = !disableTooltipSharedVal;
            chartOptions.xAxis = {
                tickInterval: tickIntervalVal,
                opposite: secondaryYaxisVal,
                categories: chartData.categories,
                visible: !disableYaxisVal,
                lineColor: hideYaxisLineVal ? 'transparent' : '#ccd6eb',
                labels: {
                    style: {
                        color: textColorVal
                    }
                },
                // reversed: true
                // startOnTick: true,
                // endOnTick: true
            };
            chartOptions.yAxis = {
                // visible: !disableXaxisVal,
                opposite: secondaryXaxisVal,
                reversed: reverseYaxisVal,
                gridLineWidth: disableXaxisVal ? 0 : 1,
                lineColor: hideXaxisLineVal ? 'transparent' : '#ccd6eb',
                labels: {
                    enabled: !disableXaxisVal,
                    formatter: function () {
                        return isPercentageStacking ? numeral((this.value || 0) / 100).format('0%') : formatChartAxisValue(Math.abs(this.value), seriesDataType);
                    },
                    style: {
                        color: textColorVal
                    }
                },
                stackLabels: {
                    enabled: enableStackDatalabelsVal,
                    align: reverseYaxisVal ? 'left' : 'right',
                    textAlign: reverseYaxisVal ? 'right' : 'left',
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.total), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                },
                plotLines: getPlotLines(
                    enableRefLineVal,
                    refLinesConfig,
                    refLineValueVal,
                    refLineStyleVal,
                    refLineColorVal,
                    refLineLabelVal,
                    refLineLabelHorizPositionVal,
                    refLineLabelVertPositionVal,
                    refLineXValueVal,
                    refLineYValueVal,
                    textColorVal,
                    refLineZPositionVal
                )
            };
            chartOptions.series = chartData.series
            if (["stacked-bar-chart"].includes(chartType) && isPercentageStacking)
                chartOptions.tooltip = {
                    formatter: function () {
                        let tooltipHtml = `<table tablespacing="0" tablepadding="0"><tr>
                            <th colspan="4" style="padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd">
                                ${(this.x || this.key || "").replace(/_br_/g, " ")}
                            </th>
                        </tr>`;
                        this.points?.forEach(p => tooltipHtml += `<tr>
                                <td>
                                    <div style="height: 10px; width: 10px; background-color: ${p.color}; margin-right: 3px"></div></td>
                                <td>${p.series.name}</td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>${formatChartTooltipValue(p.y, seriesDataType, seriesDecimalDataTypeDecimalpoints)}</b>
                                </td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>(${numeral(toNumber(p.percentage) / 100).format('0.0%')})</b>
                                </td>
                            </tr>
                        `);
                        tooltipHtml += '</table>';
                        return tooltipHtml;
                    },
                };
            break;
        case "line-chart":
        case "area-chart":
        case "column-chart":
        case "stacked-column-chart":
            if (["column-chart", "stacked-column-chart", "area-chart"].includes(chartType))
                chartOptions.tooltip.shared = !disableTooltipSharedVal;
            chartOptions.xAxis = {
                tickInterval: tickIntervalVal,
                opposite: secondaryXaxisVal,
                categories: chartData.categories,
                visible: !disableXaxisVal,
                lineColor: hideXaxisLineVal ? 'transparent' : '#ccd6eb',
                labels: {
                    style: {
                        color: textColorVal
                    }
                }
                // startOnTick: true,
                // endOnTick: true
            };

            chartOptions.yAxis = {
                opposite: secondaryYaxisVal,
                // visible: !disableYaxisVal,
                reversed: reverseXaxisVal,
                gridLineWidth: disableYaxisVal ? 0 : 1,
                lineColor: hideYaxisLineVal ? 'transparent' : '#ccd6eb',
                labels: {
                    enabled: !disableYaxisVal,
                    formatter: function () {
                        return isPercentageStacking ? numeral((this.value || 0) / 100).format('0%') : formatChartAxisValue(Math.abs(this.value), seriesDataType);
                    },
                    style: {
                        color: textColorVal
                    }
                },
                stackLabels: {
                    enabled: enableStackDatalabelsVal,
                    verticalAlign: reverseXaxisVal ? 'bottom' : 'top',
                    y: reverseXaxisVal ? 25 : undefined,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.total), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                },
                plotLines: getPlotLines(
                    enableRefLineVal,
                    refLinesConfig,
                    refLineValueVal,
                    refLineStyleVal,
                    refLineColorVal,
                    refLineLabelVal,
                    refLineLabelHorizPositionVal,
                    refLineLabelVertPositionVal,
                    refLineXValueVal,
                    refLineYValueVal,
                    textColorVal,
                    refLineZPositionVal
                )
            };
            if (["area-chart"].includes(chartType)) {
                chartOptions.plotOptions = {
                    areaspline: {
                        stacking: 'normal',
                        smooth: true,
                        softThreshold: true,
                        lineWidth: 0,
                        pointPlacement: 'on',
                        connectNulls: true,
                        marker: {
                            enabled: false
                        }
                    },
                    series: {
                        type: 'areaspline'
                    }
                }
            }
            if (["line-chart"].includes(chartType)) {
                const hasNegativeValues = chartData.series?.some(s => s.data?.some(d => toNumber(d) < 0));
                if (!hasNegativeValues)
                    chartOptions.yAxis["min"] = 0;
            }

            chartOptions.series = chartData.series;
            if (["stacked-column-chart", "area-chart"].includes(chartType) && isPercentageStacking)
                chartOptions.tooltip = {
                    formatter: function () {
                        let tooltipHtml = `<table tablespacing="0" tablepadding="0"><tr>
                            <th colspan="4" style="padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd">
                                ${(this.x || this.key || "").replace(/_br_/g, " ")}
                            </th>
                        </tr>`;
                        this.points?.forEach(p => tooltipHtml += `<tr>
                                <td>
                                    <div style="height: 10px; width: 10px; background-color: ${p.color}; margin-right: 3px"></div></td>
                                <td>${p.series.name}</td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>${formatChartTooltipValue(p.y, seriesDataType, seriesDecimalDataTypeDecimalpoints)}</b>
                                </td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>(${numeral(toNumber(p.percentage) / 100).format('0.0%')})</b>
                                </td>
                            </tr>
                        `);
                        tooltipHtml += '</table>';
                        return tooltipHtml;
                    },
                };
            break;
        case "combination-chart": {
            const seriesDataType2 = chartData.series?.length > 1 ? chartData.series[1].dataType || "currency" : "currency";
            const seriesDecimalDataTypeDecimalpoints2 = chartData.series?.length > 0 ? chartData.series[1]?.decimalPoints || 0 : 0;
            chartOptions.tooltip.shared = !disableTooltipSharedVal;
            chartOptions.xAxis = {
                tickInterval: tickIntervalVal,
                opposite: secondaryXaxisVal,
                categories: chartData.categories,
                visible: !disableXaxisVal,
                lineColor: hideXaxisLineVal ? 'transparent' : '#ccd6eb',
                labels: {
                    style: {
                        color: textColorVal
                    }
                }
                // startOnTick: true,
                // endOnTick: true
            };
            chartOptions.yAxis = [{
                opposite: secondaryYaxisVal,
                // visible: !disableYaxisVal,
                reversed: reverseXaxisVal,
                gridLineWidth: disableYaxisVal ? 0 : 1,
                lineColor: hideYaxisLineVal ? 'transparent' : '#ccd6eb',
                title: {
                    text: yAxis1TitleVal || '',
                    style: {
                        color: textColorVal
                    }
                },
                labels: {
                    enabled: !disableYaxisVal,
                    formatter: function () {
                        return isPercentageStacking ? numeral((this.value || 0) / 100).format('0%') : formatChartAxisValue(Math.abs(this.value), seriesDataType);
                    },
                    style: {
                        color: textColorVal
                    }
                },
                stackLabels: {
                    enabled: enableStackDatalabelsVal,
                    verticalAlign: reverseXaxisVal ? 'bottom' : 'top',
                    y: reverseXaxisVal ? 25 : undefined,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.total), seriesDataType, seriesDecimalDataTypeDecimalpoints);
                    }
                }
            },
            {
                opposite: true,
                // visible: !disableYaxisVal,
                reversed: reverseXaxisVal,
                gridLineWidth: disableYaxisVal ? 0 : 1,
                lineColor: hideYaxisLineVal ? 'transparent' : '#ccd6eb',
                title: {
                    text: yAxis2TitleVal || '',
                    style: {
                        color: textColorVal
                    }
                },
                labels: {
                    enabled: !disableYaxisVal,
                    formatter: function () {
                        return isPercentageStacking ? numeral((this.value || 0) / 100).format('0%') : formatChartAxisValue(Math.abs(this.value), seriesDataType2);
                    },
                    style: {
                        color: textColorVal
                    }
                },
                stackLabels: {
                    enabled: enableStackDatalabelsVal,
                    verticalAlign: reverseXaxisVal ? 'bottom' : 'top',
                    y: reverseXaxisVal ? 25 : undefined,
                    formatter: function () {
                        return formatChartDataLabelValue(Math.abs(this.total), seriesDataType2, seriesDecimalDataTypeDecimalpoints2);
                    }
                }
            }
            ];
            chartOptions.series = chartData.series;
            break;
        }
        case "pie-chart":
        case "donut-chart":
            chartOptions.series = chartData.series
            chartOptions.tooltip = {
                formatter: function () {
                    let tooltipHtml = `
                        <table tablespacing="0" tablepadding="0">
                            <tr>
                                <th colspan="4" style="padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd">
                                    ${(this.key || "").replace(/_br_/g, " ")}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <div style="height: 10px; width: 10px; background-color: ${this.color}; margin-right: 3px"></div>
                                    </td>
                                <td>${this.series?.name}</td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>${formatChartTooltipValue(this.y, seriesDataType, seriesDecimalDataTypeDecimalpoints)}</b>
                                </td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>${numeral(this.percentage / 100).format('0.0%')}</b>
                                </td>
                            </tr>
                        </table>
                    `;
                    return tooltipHtml;
                },
            }
            break;
        case "treemap-chart":
            chartOptions.chart = {
                spacingBottom: 0,
                spacingTop: 0,
                spacingRight: 0,
                spacingLeft: 0,
            };
            chartOptions.series = chartData.series;
            chartOptions.tooltip = {
                formatter: function () {
                    let tooltipHtml = `
                        <table tablespacing="0" tablepadding="0">
                            <tr>
                                <th colspan="3" style="padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd">
                                    ${(this.key || "").replace(/_br_/g, " ")}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <div style="height: 10px; width: 10px; background-color: ${this.color}; margin-right: 3px"></div>
                                    </td>
                                <td>${this.series?.name}</td>
                                <td style="text-align: right; padding-left: 3px">
                                    <b>${formatChartTooltipValue(this.point?.value, seriesDataType, seriesDecimalDataTypeDecimalpoints)}</b>
                                </td>
                            </tr>
                        </table>
                    `;
                    return tooltipHtml;
                },
            }
            break;
        case "sankey-chart":
            chartOptions.series = chartData.series;
            chartOptions.tooltip = {
                nodeFormatter: function () {
                    return this.name + ": <b>" + formatChartTooltipValue(this.sum, seriesDataType, seriesDecimalDataTypeDecimalpoints) + "</b><br/>"
                },
                formatter: undefined,
                pointFormatter: function () {
                    const from = this.from?.split("__")[1];
                    const to = this.to?.split("__")[1];
                    return from + " → " + to + ": <b>" + formatChartTooltipValue(this.weight, seriesDataType, seriesDecimalDataTypeDecimalpoints) + "</b>"
                }
            };
            if (hideLabels) {
                chartOptions.chart = {
                    spacingTop: 10,
                    spacingBottom: 15,
                    spacingLeft: 15,
                    spacingRight: 15,
                    events: {
                        render: function () {
                            if (this.customLabels) {
                                this.customLabels.forEach((customLabel) => {
                                    customLabel.attr({
                                        display: "none",
                                    });
                                });
                            }
                        }
                    },
                }
            }
            else {
                const labelsOnTop = labelsPosition === "top";
                chartOptions.chart = {
                    spacingTop: labelsOnTop ? 50 : 10,
                    spacingBottom: !labelsOnTop ? 50 : 15,
                    spacingLeft: 15,
                    spacingRight: 15,
                    events: {
                        render: function () {
                            const labels = chartData.labels;
                            const labelWidth = this.chartWidth / (labels?.length - 1);
                            const positions = labels.map((l, i) => {
                                if (i === 0)
                                    return 50
                                else if (i === labels.length - 1)
                                    return this.chartWidth - 50;
                                return (labelWidth * i)
                            });
                            if (this.customLabels) {
                                this.customLabels.forEach((customLabel, i) => {
                                    customLabel.attr({
                                        x: positions[i],
                                        y: labelsOnTop ? 30 : this.chartHeight - 20,
                                        display: "inherit",
                                    });
                                });
                            } else {
                                this.customLabels = [];
                                labels?.forEach((label, i) => {
                                    this.customLabels.push(
                                        this.renderer.text(label)
                                            .attr({
                                                x: positions[i],
                                                y: labelsOnTop ? 30 : this.chartHeight - 20,
                                                align: 'center'
                                            })
                                            .css({
                                                fontSize: '11px',
                                                fontWeight: '600',
                                                fill: textColorVal,
                                                color: textColorVal
                                            })
                                            .add()
                                    );
                                });
                            }
                        }
                    }
                };
            }
            break;
        default:
            break;
    }
    return chartOptions;
};

export const generateChartOptions = (chartType, size, vizOptions, data, standardVariables = {}) => {
    const chartOptions = getChartOptions(chartType, vizOptions, data, standardVariables);
    const { height, width } = size;

    setAutoFreeze(false);

    return produce(baseConfig, draft => {
        draft.chart.type = chartOptions.chartType;
        draft.chart.height = height;
        draft.chart.width = width;
        if (chartOptions.chart)
            Object.assign(draft.chart, chartOptions.chart);

        draft.colors = chartOptions.colors;
        draft.legend = chartOptions.legend;

        if (chartOptions.tooltip)
            Object.assign(draft.tooltip, chartOptions.tooltip);
        if (chartOptions.xAxis)
            draft.xAxis = _.merge(draft.xAxis, chartOptions.xAxis)
        if (chartOptions.yAxis) {
            if (chartType === "combination-chart")
                draft.yAxis = chartOptions.yAxis;
            else
                Object.assign(draft.yAxis, chartOptions.yAxis);
        }
        if (chartOptions.series)
            Object.assign(draft.series, chartOptions.series);
        if (chartOptions.plotOptions)
            Object.assign(draft.plotOptions, chartOptions.plotOptions);
    });
}
